const util = require('../util');

exports.init = function error() {
    $('.recommendations-container .tiles-container').slick({
        speed: 300,
        dots: false,
        arrows: true,
        slide: '.grid-tile',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 690,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
    util.initDynamicCarousel('[id^="cq_recomm_slot"]', '.recommendations-container .tiles-container', 0);
};
